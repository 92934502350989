import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../utils/Context';
import Spinner from './Spinner';
import { useEffect } from 'react';
import axios from 'axios';

export default function ProtectedRoute() {
    const location = useLocation();
    const [authContext, setAuthState] = useAuthContext((state) => [state.auth, state.changeState]);

    const navigate = useNavigate();

    useEffect(() => {
        axios.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response.status === 401) {
                    setAuthState({
                        firstName: '',
                        lastName: '',
                        email: '',
                        token: '',
                        isLoggedIn: false,
                        plan: 0,
                        orgName: '',
                        orgRole: '',
                    });
                    setTimeout(() => {
                        navigate(`/login?returnTo=${location.pathname}`);
                    }, 250);
                }
            },
        );
    }, []);

    return authContext.isLoggedIn === undefined ? (
        <div className="flex h-[90vh] justify-center items-center flex-col">
            <Spinner h="h-32" w="w-32" />
            <h1 className="mt-6 text-2xl leading-7 text-gray-600 w-32 text-center">Loading...</h1>
        </div>
    ) : authContext.isLoggedIn ? (
        <Outlet />
    ) : (
        <Navigate to={`/login?returnTo=${location.pathname}`} />
    );
}
