import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';

export function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

export function countEventsPerDay(events: any, momentDate: dayjs.Dayjs) {
    const length = events.filter((event: any) => dayjs(event.startDateTime).isSame(momentDate, 'day')).length;
    return length > 3 ? 3 : length;
}

export function getDaysForCalendarMonth(year: number, month: number, selectedDate: Dayjs | null, events: any) {
    const todayDate = dayjs();
    const date = new Date(year, month - 1);
    const firstDayOfMonth = dayjs(date).startOf('month');
    const firstDayOfCal = firstDayOfMonth.clone().startOf('week');
    const lastDayOfMonth = firstDayOfMonth.clone().endOf('month');
    const lastDayOfCal = lastDayOfMonth.clone().endOf('week');
    let temp = firstDayOfCal.clone();
    const days = [
        {
            date: temp.format('YYYY-MM-DD'),
            isToday: temp.isSame(todayDate, 'day'),
            isCurrentMonth: temp.isSame(date, 'month'),
            isSelected: temp.isSame(selectedDate, 'day'),
            numberOfEvents: events ? countEventsPerDay(events, temp) : 0,
        },
    ];
    while (temp.isBefore(lastDayOfCal) && days.length < 42) {
        temp = temp.add(1, 'day');
        days.push({
            date: temp.format('YYYY-MM-DD'),
            isToday: temp.isSame(todayDate, 'day'),
            isCurrentMonth: temp.isSame(date, 'month'),
            isSelected: temp.isSame(selectedDate, 'day'),
            numberOfEvents: events ? countEventsPerDay(events, temp) : 0,
        });
    }
    while (days.length < 42) {
        temp = temp.add(1, 'day');
        days.push({
            date: temp.format('YYYY-MM-DD'),
            isToday: temp.isSame(todayDate, 'day'),
            isCurrentMonth: temp.isSame(date, 'month'),
            isSelected: temp.isSame(selectedDate, 'day'),
            numberOfEvents: events ? countEventsPerDay(events, temp) : 0,
        });
    }
    return days;
}

export function setAxiosDomains() {
    if (import.meta.env.MODE === 'dev') {
        axios.defaults.baseURL = 'https://api-dev.webbsly.com';
    } else if (import.meta.env.MODE === 'prod') {
        axios.defaults.baseURL = 'https://api.webbsly.com';
    } else {
        axios.defaults.baseURL = 'http://localhost:3000';
    }
}

export function canUserAccess(userRole: string, reqRole: string) {
    if (reqRole === userRole) {
        return true;
    }
    if (reqRole === 'User' && (userRole === 'Manager' || userRole === 'Admin')) {
        return true;
    }
    if (reqRole === 'Manager' && userRole === 'Admin') {
        return true;
    }
    return false;
}

export const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export function parseNumberToHours(number: number): string {
    let str: string = '';
    const modulo = number % 1;
    if (number < 0) {
        str = modulo === 0 ? `${number}:00` : `${number - modulo}:${Math.abs(modulo * 60)}`;
    } else if (number > 0) {
        str = modulo === 0 ? `+${number}:00` : `+${number - modulo}:${modulo * 60}`;
    } else if (number === 0) {
        str = '+0:00';
    }

    return str;
}

export function getTimezoneAbbr(date: Date, timeZone: string) {
    const split = date.toLocaleString('en-US', { timeZone: timeZone, timeZoneName: 'long' }).split(' ');
    split.splice(0, 3);
    const tzShortName = split.map((str) => str.charAt(0)).join('');
    return tzShortName;
}
