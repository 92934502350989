import { ExclamationTriangleIcon, XCircleIcon, CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import { classNames } from '../domain/helpers';

type AlertProps = {
    alertType: string;
    message2?: string;
    message: string;
    className?: string;
};

function Alert({ alertType, message2, message, className = ""}: AlertProps) {

    const color = (type: string): string => {
        switch (type) {
            case "error":
                return "red"
            case "success":
                return "green"
            case "info":
                return "blue"
            case "warning":
                return "yellow"
            default:
                return "gray"
        }
    }

    const renderIcon = (type: string) => {
        switch (type) {
            case "error":
                return <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
            case "success":
                return <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
            case "info":
                return <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
            case "warning":
                return <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
            default:
                <></>
        }
    }

    return (
        <div className={classNames(`rounded-md bg-${color(alertType)}-50 p-4`, className)}>
            <div className="flex">
                <div className="flex-shrink-0">
                    {renderIcon(alertType)}
                </div>
                <div className="ml-3">
                    <h3 className={`text-sm font-medium text-${color(alertType)}-800`}>{message}</h3>
                    {message &&
                        (
                            <div className={`mt-2 text-sm text-${color(alertType)}-700`}>
                                <p>
                                    {message2}
                                </p>
                            </div>
                        )
                    }

                </div>
            </div>
        </div>
    )
}

export default Alert;