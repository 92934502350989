import { Fragment } from 'react';
import { Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { useNotificationContext } from '../utils/Context';

export default function Notification({
    acceptName = 'Confirm',
    declineName = 'Cancel',
    acceptFunction = () => {},
    declineFunction = () => {},
    heading,
    message,
    icon,
    isOpen,
}: {
    acceptName?: string;
    declineName?: string;
    acceptFunction?: () => void;
    declineFunction?: () => void;
    heading: string;
    message: string;
    icon?: any;
    isOpen: boolean;
}) {
    const setNotificationContext = useNotificationContext((state) => state.changeState);
    const resetNotificationContext = () => {
        setNotificationContext({
            isOpen: false,
            acceptName: 'Confirm',
            declineName: 'Cancel',
            acceptFunction: () => {},
            declineFunction: () => {},
            heading: '',
            message: '',
            icon: null,
        });
    };
    return (
        <>
            {/* Global notification live region, render this permanently at the end of the document */}
            <div
                aria-live="assertive"
                className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 mt-16 z-[100000]">
                <div className="flex min-h-full w-full flex-col items-center space-y-4 sm:items-end">
                    {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                    <Transition
                        show={isOpen}
                        as={Fragment}
                        enter="transform ease-out duration-300 transition"
                        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <div className="pointer-events-auto w-full max-w-sm rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 bg-opacity-100 transition-opacity">
                            <div className="p-4">
                                <div className="flex items-start">
                                    <div className="flex-shrink-0 pt-0.5">
                                        {icon}
                                        {/* <Icon
                                                className="h-10 w-10 rounded-full"
                                            /> */}
                                    </div>
                                    <div className="ml-3 w-0 flex-1">
                                        <p className="text-lg font-medium text-gray-900">{heading}</p>
                                        <p
                                            className="mt-1 text-sm text-gray-500"
                                            dangerouslySetInnerHTML={{ __html: message }}
                                        />
                                        <div className="mt-4 flex">
                                            <button
                                                onClick={() => {
                                                    resetNotificationContext();
                                                    acceptFunction();
                                                }}
                                                type="button"
                                                className="inline-flex items-center rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                                {acceptName}
                                            </button>
                                            <button
                                                onClick={() => {
                                                    resetNotificationContext();
                                                    declineFunction();
                                                }}
                                                type="button"
                                                className="ml-3 inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                                {declineName}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="ml-4 flex flex-shrink-0">
                                        <button
                                            type="button"
                                            className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            onClick={() => {
                                                resetNotificationContext();
                                            }}>
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </>
    );
}
