import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import logoNoBg from '../assets/logo-no-bg.png';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Alert from '../components/Alert';
import { useAuthContext } from '../utils/Context';
import CreateNewUserChildren from '../components/CreateNewUser';

function ActivateAccount() {
    // { userId, uuid, frequency }
    const params = useParams();
    const navigate = useNavigate();
    const [alerts, setAlerts] = useState<AlertType>(null);
    const [context, setContext] = useAuthContext((state) => [state.auth, state.changeState]);

    useEffect(() => {
        if (context.isLoggedIn) {
            return navigate('/dashboard');
        }
        if (!params.orgId)
            axios
                .get(`/auth/verify/${params.userId?.trim()}/${params.uuid?.trim()}`)
                .then((response) => {
                    setContext({
                        firstName: response.data.firstName as string,
                        lastName: response.data.lastName as string,
                        email: response.data.email as string,
                        token: response.data.token as string,
                        plan: response.data.plan as number,
                        isLoggedIn: true,
                        orgName: response.data.organisationName,
                        orgRole: response.data.orgRole,
                    });

                    setAlerts({
                        alertType: 'success',
                        message: `Account successfully activated. "Loading billing panel...`,
                    });

                    setTimeout(() => {
                        setAlerts(null);
                        navigate('/dashboard');
                    }, 1500);
                })
                .catch((err) => {
                    setAlerts({
                        alertType: 'error',
                        message: err.response.data.message,
                    });
                });
    });

    return (
        <>
            <Helmet>
                <title>Billing | Webbsly</title>
                <link rel="canonical" href={window.location.href} />
                <meta name="description" content="Billing | Webbsly" />
            </Helmet>
            <img className="mx-auto h-20 w-auto my-6" src={logoNoBg} alt="Webbsly" />
            {params.orgId ? (
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <h1 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 mb-5">
                        Set your account's password
                    </h1>
                    <CreateNewUserChildren
                        orgId={params.orgId}
                        userId={params.userId}
                        activateEmailToken={params.uuid?.trim()}
                    />
                </div>
            ) : (
                <h1 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Account Activated
                </h1>
            )}
            {alerts && (
                <Alert
                    className="flex min-h-full flex-1 flex-col justify-center px-6 lg:px-8 mx-auto sm:w-full max-w-sm mt-3"
                    alertType={alerts.alertType}
                    message={alerts.message}
                />
            )}
        </>
    );
}

export default ActivateAccount;
