import { useEffect, useRef, useState } from 'react';

export const useEffectOnce = (effect: () => void | (() => void)) => {
    const destroyFunc = useRef<void | (() => void)>();
    const effectCalled = useRef(false);
    const renderAfterCalled = useRef(false);
    const [_, setVal] = useState<number>(0);

    if (effectCalled.current) {
        renderAfterCalled.current = true;
    }

    useEffect(() => {
        // only execute the effect first time around
        if (!effectCalled.current) {
            destroyFunc.current = effect();
            effectCalled.current = true;
        }

        // this forces one render after the effect is run
        setVal((val) => val + 1);

        return () => {
            // if the comp didn't render since the useEffect was called,
            // we know it's the dummy React cycle
            if (!renderAfterCalled.current) {
                return;
            }
            if (destroyFunc.current) {
                destroyFunc.current();
            }
        };
    }, []);
};

export const checkPasswordReq = (password: string, setPasswordReq: (prevState?: any) => void) => {
    if (password === undefined || password === '')
        //@ts-ignore
        setPasswordReq((prevState) => prevState.map((item) => ({ ...item, isValid: false })));

    setPasswordReq([
        {
            id: 'minLength',
            text: 'Min. 12 characters',
            isValid: password.length > 11 ? true : false,
        },
        {
            id: 'minUppercase',
            text: '1 uppercase',
            isValid: /^.*[A-Z]+.*$/gm.test(password) ? true : false,
        },
        {
            id: 'minDigits',
            text: '1 digit',
            isValid: /^.*\d+.*$/gm.test(password) ? true : false,
        },
        {
            id: 'minSpecialCharacters',
            text: '1 special character',
            isValid: /^.*[\*\.\!@\$\%\^\&\(\)\{\}\[\]\:\;\<\>\,\.\?\/\~\_\+\-\=\|]+.*/gm.test(password) ? true : false,
        },
    ]);
};

export const togglePasswordVisibility = (e: any, setShowPassword: (fn: (prevState: boolean) => boolean) => void) => {
    e.preventDefault();
    setShowPassword((prevState) => !prevState);
};
