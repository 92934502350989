import { setAxiosDomains } from '../domain/helpers';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

type AuthContextType = {
    firstName: string;
    lastName: string;
    email: string;
    token: string;
    isLoggedIn?: boolean;
    plan: number;
    orgName: string;
    orgRole: string;
};

type AuthContextStateType = {
    auth: AuthContextType;
    changeState: (newState: AuthContextType) => void;
};

type ModalContextType = {
    isOpen: boolean;
    children: React.ReactNode;
    callBackOnClose?: () => void;
};

type ModalContextStateType = {
    modalContext: ModalContextType;
    changeState: (newState: ModalContextType) => void;
};

type NotificationContextType = {
    acceptName: string;
    declineName: string;
    acceptFunction: () => void;
    declineFunction: () => void;
    heading: string;
    message: string;
    isOpen: boolean;
    icon: any | null;
};

type NotificationContextStateType = {
    notificationContext: NotificationContextType;
    changeState: (newState: NotificationContextType) => void;
};
interface ModalContextResponseType {
    data: JSX.Element | null;
    changeState: (newState: JSX.Element | null) => void;
}

export const useAuthContext = create<AuthContextStateType>()(
    devtools(
        persist(
            (set) => ({
                auth: {
                    firstName: '',
                    lastName: '',
                    email: '',
                    token: '',
                    isLoggedIn: false,
                    plan: 0,
                    orgName: '',
                    orgRole: '',
                },
                changeState: (newState) => set(() => ({ auth: newState })),
            }),
            {
                name: 'auth-state',
            },
        ),
    ),
);

export const useModalContextResponse = create<ModalContextResponseType>()(
    devtools(
        (set) => ({
            data: null,
            changeState: (newState) => set(() => ({ data: newState })),
        }),
        {
            name: 'modal-response',
        },
    ),
);

export const useModalContext = create<ModalContextStateType>()(
    devtools(
        (set) => ({
            modalContext: {
                isOpen: false,
                children: null,
                callBackOnClose: undefined,
            },
            changeState: (newState) => set(() => ({ modalContext: newState })),
        }),
        { name: 'modal-context' },
    ),
);

export const useNotificationContext = create<NotificationContextStateType>()(
    devtools(
        (set) => ({
            notificationContext: {
                acceptName: 'Confirm',
                declineName: 'Cancel',
                acceptFunction: () => {},
                declineFunction: () => {},
                heading: '',
                message: '',
                isOpen: false,
                icon: null,
            },
            changeState: (newState) => set(() => ({ notificationContext: newState })),
        }),
        { name: 'notification-context' },
    ),
);

setAxiosDomains();
