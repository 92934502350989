import { Listbox, Transition } from '@headlessui/react';
import Button from './Button';
import Spinner from './Spinner';
import { CheckIcon, ChevronUpDownIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { Fragment, useEffect, useRef, useState } from 'react';
import TextInput from './TextInput';
import axios from 'axios';
import { checkPasswordReq, togglePasswordVisibility } from '../utils/Helpers';
import { checkMarkBlackRegister, checkMarkGreenRegister } from '../domain/constants';
import { classNames } from '../domain/helpers';
import Alert from './Alert';

export default function CreateNewUserChildren({
    orgId,
    userId,
    activateEmailToken,
}: {
    orgId?: string;
    userId?: string;
    activateEmailToken?: string;
}) {
    const [selectedRole, setSelectedRole] = useState<string>('');
    const [createUserPending, setCreateUserPending] = useState(false);
    const [userRoles, setUserRoles] = useState<string[]>([]);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [alerts, setAlerts] = useState<AlertType>(null);
    const [passwordReq, setPasswordReq] = useState<RegisterPasswordRequirements[]>([
        {
            id: 'minLength',
            text: 'Min. 12 characters',
            isValid: false,
        },
        {
            id: 'minUppercase',
            text: '1 uppercase',
            isValid: false,
        },
        {
            id: 'minDigits',
            text: '1 digit',
            isValid: false,
        },
        {
            id: 'minSpecialCharacters',
            text: '1 special character',
            isValid: false,
        },
    ]);
    const ref = useRef<HTMLFormElement>(null);

    useEffect(() => {
        axios
            .get('/organisation/roles')
            .then((res) => {
                setUserRoles(Object.keys(res.data));
                if (!orgId) setSelectedRole(Object.keys(res.data)[0]);
                if (orgId) {
                    axios
                        .get(`/organisation/getUserInfo/${userId}`)
                        .then((res) => {
                            console.log(res.data);
                            const { firstName, lastName, email, orgRole } = res.data;
                            ref.current!.firstName.value = firstName;
                            ref.current!.lastName.value = lastName;
                            ref.current!.email.value = email;
                            setSelectedRole(orgRole);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    function onSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setCreateUserPending(true);

        if (orgId) {
            axios
                .post('/organisation/activateUser', {
                    id: userId,
                    verifyEmailToken: activateEmailToken,
                    password: e.currentTarget.password.value,
                })
                .then((_) => {
                    setCreateUserPending(false);
                })
                .catch((err) => {
                    console.log(err.response.data);
                    setCreateUserPending(false);
                    setAlerts({
                        alertType: 'error',
                        message: err.response.data.message,
                    });
                });
        } else {
            axios
                .post('/organisation/addUser', {
                    email: e.currentTarget.email.value,
                    firstName: e.currentTarget.firstName.value,
                    lastName: e.currentTarget.lastName.value,
                    role: selectedRole,
                })
                .then((_) => {
                    setCreateUserPending(false);
                })
                .catch((err) => {
                    console.log(err.response.data);
                    setCreateUserPending(false);
                    setAlerts({
                        alertType: 'error',
                        message: err.response.data.message,
                    });
                });
        }
    }

    return (
        <form className="space-y-6 h-[50vh]" onSubmit={onSubmit} ref={ref}>
            <div className="flex justify-between flex-col md:flex-row max-md:space-y-6">
                <div className="w-full md:w-[48%]">
                    <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-900">
                        First Name
                    </label>
                    <div className="mt-2">
                        <TextInput
                            id="firstName"
                            name="firstName"
                            type="text"
                            autocomplete="given-name"
                            placeholder="John"
                            className={classNames(orgId ? 'cursor-not-allowed' : '')}
                            required={true}
                            readOnly={orgId ? true : false}
                            disabled={orgId ? true : false}
                        />
                    </div>
                </div>
                <div className="w-full md:w-[48%]">
                    <label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-900">
                        Last Name
                    </label>
                    <div className="mt-2">
                        <TextInput
                            id="lastName"
                            name="lastName"
                            className={classNames(orgId ? 'cursor-not-allowed' : '')}
                            type="text"
                            autocomplete="family-name"
                            placeholder="Doe"
                            required={true}
                            readOnly={orgId ? true : false}
                            disabled={orgId ? true : false}
                        />
                    </div>
                </div>
            </div>
            <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Email address
                </label>
                <div className="mt-2">
                    <TextInput
                        id="email"
                        name="email"
                        className={classNames(orgId ? 'cursor-not-allowed' : '')}
                        type="email"
                        autocomplete="email"
                        placeholder="john.doe@example.com"
                        required={true}
                        readOnly={orgId ? true : false}
                        disabled={orgId ? true : false}
                    />
                </div>
            </div>

            <div>
                <label className="block text-sm font-medium leading-6 text-gray-900">Role</label>
                <Listbox value={selectedRole} onChange={setSelectedRole} disabled={orgId ? true : false}>
                    <div className="relative mt-1 w-full">
                        <Listbox.Button
                            id="role"
                            className={classNames(
                                'relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm',
                                orgId ? 'cursor-not-allowed' : '',
                            )}>
                            <span className="block truncate">{selectedRole}</span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </Listbox.Button>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0">
                            <Listbox.Options className="z-[9999] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {userRoles.map((person, personIdx) => (
                                    <Listbox.Option
                                        key={personIdx}
                                        className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                                            }`
                                        }
                                        disabled={orgId ? true : false}
                                        value={person}>
                                        {({ selected }) => (
                                            <>
                                                <span
                                                    className={`block truncate ${
                                                        selected ? 'font-medium' : 'font-normal'
                                                    }`}>
                                                    {person}
                                                </span>
                                                {selected ? (
                                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </Listbox>
            </div>

            {orgId && (
                <>
                    <div>
                        <div className="flex items-center justify-between">
                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                Password
                            </label>
                        </div>
                        <div className="mt-2 relative z-0">
                            <TextInput
                                id="password"
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                autocomplete="password"
                                required={true}
                                // className='absolute z-1'
                                onChange={(e) => checkPasswordReq(e.target.value, setPasswordReq)}
                            />
                            <button
                                className="absolute inset-y-0 right-0 flex items-center pr-2"
                                onClick={(e) => togglePasswordVisibility(e, setShowPassword)}
                                tabIndex={-1}
                                type="button">
                                {showPassword ? (
                                    <EyeSlashIcon className="w-6 h-6 stroke-gray-600" />
                                ) : (
                                    <EyeIcon className="w-6 h-6 stroke-gray-600" />
                                )}
                            </button>
                        </div>
                    </div>
                    <div>
                        <ul className="pl-6">
                            {passwordReq.map((req, index) => (
                                <li
                                    style={{
                                        listStyleImage: `url('data:image/svg+xml;base64,${
                                            req.isValid ? checkMarkGreenRegister : checkMarkBlackRegister
                                        }')`,
                                    }}
                                    key={index}
                                    className={classNames(
                                        'pl-2 text-sm',
                                        req.isValid ? 'text-[#28b91c]' : 'text-gray-900',
                                    )}>
                                    {req.text}
                                </li>
                            ))}
                        </ul>
                    </div>
                </>
            )}

            {createUserPending && <Spinner className="w-full text-center" />}

            {alerts && <Alert alertType={alerts.alertType} message={alerts.message} />}

            <div>
                <Button disabled={createUserPending} type="submit">
                    Activate Account
                </Button>
            </div>
        </form>
    );
}
