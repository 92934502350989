import { Fragment, Key, useEffect, useState } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, UserCircleIcon, CalendarDaysIcon, Squares2X2Icon } from '@heroicons/react/24/outline';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import logoNoBg from '../assets/logo-no-bg.png';
import { useAuthContext, useNotificationContext } from '../utils/Context';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { classNames } from '../domain/helpers';

import { Dialog } from '@headlessui/react';
import { ChartPieIcon, Cog6ToothIcon, HomeIcon, PlusIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import NavSearch from './NavSearch';

const webinarNavigation = [
    // { name: 'Dashboard', href: '#', icon: HomeIcon, current: true },
    { name: 'Create a Webinar', href: '/dashboard/webinars/create', icon: PlusIcon },
    // { name: 'List of Webinars', href: '/dashboard/webinars/view', icon: ListBulletIcon },
    { name: 'Calendar', href: '/dashboard/calendar', icon: CalendarDaysIcon },
    { name: 'Content', href: '/dashboard/content', icon: Squares2X2Icon },
    { name: 'Analytics', href: '/dashboard/dashboard/analytics', icon: ChartPieIcon },
    // { name: 'IDK Anymore', href: '/dashboard/reports', icon: ChartPieIcon },
];

const meetingsNavigation = [
    { name: 'Meetings', href: '/dashboard/meetings', icon: PlusIcon },
    { name: 'Hello', href: '/dashboard/meetings/hello', icon: Squares2X2Icon },
    { name: 'Wazzup', href: '/dashboard/meetings/wazzup', icon: ChartPieIcon },
];

const orgNavigation = [
    {
        id: 1,
        name: 'Organisation Management',
        href: '/dashboard/management',
        initial: 'B',
    },
];

type NavigationItem = {
    name: Key;
    href: string;
};

const navigation: NavigationItem[] = [{ name: 'Home', href: '/' }];

export default function Nav() {
    const [authContext, setAuthContext] = useAuthContext((state) => [state.auth, state.changeState]);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const location = useLocation();
    const [notificationContext, setNotificationContext] = useNotificationContext((state) => [
        state.notificationContext,
        state.changeState,
    ]);
    const navigate = useNavigate();
    const logout = () => {
        axios
            .get('/auth/logout')
            .then(() => {
                setAuthContext({
                    firstName: '',
                    lastName: '',
                    email: '',
                    token: '',
                    isLoggedIn: false,
                    plan: 0,
                    orgName: '',
                    orgRole: '',
                });
                localStorage.removeItem('auth');
            })
            .catch(() => toast.error('Failed to logout.'));
    };

    useEffect(() => {
        if (notificationContext.isOpen) {
            setNotificationContext({
                isOpen: false,
                heading: '',
                message: '',
                acceptName: '',
                declineName: '',
                acceptFunction: () => {},
                declineFunction: () => {},
                icon: null,
            });
        }
    }, [location]);

    return (
        <div className="min-h-screen">
            {location.pathname.includes('/dashboard') ? (
                <>
                    <div className="min-h-screen">
                        <Transition.Root show={sidebarOpen} as={Fragment}>
                            <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
                                <Transition.Child
                                    as={Fragment}
                                    enter="transition-opacity ease-linear duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="transition-opacity ease-linear duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0">
                                    <div className="fixed inset-0 bg-gray-900/80" />
                                </Transition.Child>

                                <div className="fixed inset-0 flex">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="transition ease-in-out duration-300 transform"
                                        enterFrom="-translate-x-full"
                                        enterTo="translate-x-0"
                                        leave="transition ease-in-out duration-300 transform"
                                        leaveFrom="translate-x-0"
                                        leaveTo="-translate-x-full">
                                        <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                            <Transition.Child
                                                as={Fragment}
                                                enter="ease-in-out duration-300"
                                                enterFrom="opacity-0"
                                                enterTo="opacity-100"
                                                leave="ease-in-out duration-300"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0">
                                                <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                                    <button
                                                        type="button"
                                                        className="-m-2.5 p-2.5"
                                                        onClick={() => setSidebarOpen(false)}>
                                                        <span className="sr-only">Close sidebar</span>
                                                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </Transition.Child>
                                            {/* Sidebar component, swap this element with another sidebar if you like */}
                                            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 ring-1 ring-white/10">
                                                <div
                                                    className="flex h-16 shrink-0 items-center text-white gap-2 cursor-pointer select-none"
                                                    onClick={() => navigate('/dashboard')}>
                                                    <p className="pl-4">Webbsly</p>
                                                    <img className="h-8 w-auto" src={logoNoBg} alt="Webbsly Logo" />
                                                </div>
                                                <nav className="flex flex-1 flex-col">
                                                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                                                        <NavLink
                                                            to="/dashboard"
                                                            end={true}
                                                            onClick={() => setSidebarOpen(false)}
                                                            className={({ isActive }) =>
                                                                classNames(
                                                                    isActive
                                                                        ? 'bg-gray-800 text-white'
                                                                        : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold -mx-2 space-y-1',
                                                                )
                                                            }>
                                                            <HomeIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                                            Dashboard
                                                        </NavLink>
                                                        <Disclosure defaultOpen>
                                                            {({ open }) => (
                                                                <>
                                                                    <Disclosure.Button
                                                                        className={classNames(
                                                                            'text-gray-400 hover:text-white hover:bg-gray-800',
                                                                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold -mx-2 space-y-1 justify-between',
                                                                        )}>
                                                                        Webinars
                                                                        <ChevronRightIcon
                                                                            className={classNames(
                                                                                open ? 'rotate-90 transform' : '',
                                                                                'h-6 w-6 shrink-0',
                                                                            )}
                                                                        />
                                                                    </Disclosure.Button>
                                                                    <Disclosure.Panel
                                                                        as="ul"
                                                                        role="list"
                                                                        className="-mx-2 space-y-1 -mt-6">
                                                                        {webinarNavigation.map((item) => (
                                                                            <li key={item.name}>
                                                                                <NavLink
                                                                                    to={item.href}
                                                                                    onClick={() =>
                                                                                        setSidebarOpen(false)
                                                                                    }
                                                                                    className={({ isActive }) =>
                                                                                        classNames(
                                                                                            isActive
                                                                                                ? 'bg-gray-800 text-white'
                                                                                                : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                                                                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                                                                        )
                                                                                    }>
                                                                                    <item.icon
                                                                                        className="h-6 w-6 shrink-0"
                                                                                        aria-hidden="true"
                                                                                    />
                                                                                    {item.name}
                                                                                </NavLink>
                                                                            </li>
                                                                        ))}
                                                                    </Disclosure.Panel>
                                                                </>
                                                            )}
                                                        </Disclosure>
                                                        <Disclosure>
                                                            {({ open }) => (
                                                                <>
                                                                    <Disclosure.Button
                                                                        className={classNames(
                                                                            'text-gray-400 hover:text-white hover:bg-gray-800',
                                                                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold -mx-2 space-y-1 justify-between',
                                                                        )}>
                                                                        Meetings
                                                                        <ChevronRightIcon
                                                                            className={classNames(
                                                                                open ? 'rotate-90 transform' : '',
                                                                                'h-6 w-6 shrink-0',
                                                                            )}
                                                                        />
                                                                    </Disclosure.Button>
                                                                    <Disclosure.Panel
                                                                        as="ul"
                                                                        role="list"
                                                                        className="-mx-2 space-y-1 -mt-6">
                                                                        {meetingsNavigation.map((item) => (
                                                                            <li key={item.name}>
                                                                                <NavLink
                                                                                    to={item.href}
                                                                                    onClick={() =>
                                                                                        setSidebarOpen(false)
                                                                                    }
                                                                                    className={({ isActive }) =>
                                                                                        classNames(
                                                                                            isActive
                                                                                                ? 'bg-gray-800 text-white'
                                                                                                : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                                                                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                                                                        )
                                                                                    }>
                                                                                    <item.icon
                                                                                        className="h-6 w-6 shrink-0"
                                                                                        aria-hidden="true"
                                                                                    />
                                                                                    {item.name}
                                                                                </NavLink>
                                                                            </li>
                                                                        ))}
                                                                    </Disclosure.Panel>
                                                                </>
                                                            )}
                                                        </Disclosure>
                                                        <li>
                                                            <div className="text-xs font-semibold leading-6 text-gray-400">
                                                                Your Organisation
                                                            </div>
                                                            <ul role="list" className="-mx-2 mt-2 space-y-1">
                                                                {orgNavigation.map((team) => (
                                                                    <li key={team.name}>
                                                                        <NavLink
                                                                            to={team.href}
                                                                            onClick={() => setSidebarOpen(false)}
                                                                            className={({ isActive }) =>
                                                                                classNames(
                                                                                    isActive
                                                                                        ? 'bg-gray-800 text-white'
                                                                                        : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                                                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                                                                )
                                                                            }>
                                                                            <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                                                                                {team.initial}
                                                                            </span>
                                                                            <span className="truncate">
                                                                                {team.name}
                                                                            </span>
                                                                        </NavLink>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </li>
                                                        <li className="mt-auto">
                                                            <NavLink
                                                                to="/dashboard/profile"
                                                                onClick={() => setSidebarOpen(false)}
                                                                className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white">
                                                                <Cog6ToothIcon
                                                                    className="h-6 w-6 shrink-0"
                                                                    aria-hidden="true"
                                                                />
                                                                Your Profile
                                                            </NavLink>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </Dialog>
                        </Transition.Root>

                        {/* Static sidebar for desktop */}
                        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                            {/* Sidebar component, swap this element with another sidebar if you like */}
                            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 text-white">
                                <div
                                    className="flex h-16 shrink-0 items-center gap-2 cursor-pointer select-none"
                                    onClick={() => navigate('/dashboard')}>
                                    <p className="pl-4">Webbsly</p>
                                    <img className="h-8 w-auto" src={logoNoBg} alt="Webbsly Logo" />
                                </div>
                                <nav className="flex flex-1 flex-col">
                                    <ul role="list" className="flex flex-1 flex-col gap-y-5 ">
                                        <NavLink
                                            to="/dashboard"
                                            end={true}
                                            className={({ isActive }) =>
                                                classNames(
                                                    isActive
                                                        ? 'bg-gray-800 text-white'
                                                        : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold -mx-2 space-y-1',
                                                )
                                            }>
                                            <HomeIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                            Dashboard
                                        </NavLink>
                                        <Disclosure defaultOpen>
                                            {({ open }) => (
                                                <>
                                                    <Disclosure.Button
                                                        className={classNames(
                                                            'text-gray-400 hover:text-white hover:bg-gray-800',
                                                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold -mx-2 space-y-1 justify-between',
                                                        )}>
                                                        Webinars
                                                        <ChevronRightIcon
                                                            className={classNames(
                                                                open ? 'rotate-90 transform' : '',
                                                                'h-6 w-6 shrink-0',
                                                            )}
                                                        />
                                                    </Disclosure.Button>
                                                    <Disclosure.Panel
                                                        as="ul"
                                                        role="list"
                                                        className="-mx-2 space-y-1 -mt-6">
                                                        {webinarNavigation.map((item) => (
                                                            <li key={item.name}>
                                                                <NavLink
                                                                    to={item.href}
                                                                    className={({ isActive }) =>
                                                                        classNames(
                                                                            isActive
                                                                                ? 'bg-gray-800 text-white'
                                                                                : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                                                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                                                        )
                                                                    }>
                                                                    <item.icon
                                                                        className="h-6 w-6 shrink-0"
                                                                        aria-hidden="true"
                                                                    />
                                                                    {item.name}
                                                                </NavLink>
                                                            </li>
                                                        ))}
                                                    </Disclosure.Panel>
                                                </>
                                            )}
                                        </Disclosure>
                                        <Disclosure>
                                            {({ open }) => (
                                                <>
                                                    <Disclosure.Button
                                                        className={classNames(
                                                            'text-gray-400 hover:text-white hover:bg-gray-800',
                                                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold -mx-2 space-y-1 justify-between',
                                                        )}>
                                                        Meetings
                                                        <ChevronRightIcon
                                                            className={classNames(
                                                                open ? 'rotate-90 transform' : '',
                                                                'h-6 w-6 shrink-0',
                                                            )}
                                                        />
                                                    </Disclosure.Button>
                                                    <Disclosure.Panel
                                                        as="ul"
                                                        role="list"
                                                        className="-mx-2 space-y-1 -mt-6">
                                                        {meetingsNavigation.map((item) => (
                                                            <li key={item.name}>
                                                                <NavLink
                                                                    to={item.href}
                                                                    className={({ isActive }) =>
                                                                        classNames(
                                                                            isActive
                                                                                ? 'bg-gray-800 text-white'
                                                                                : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                                                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                                                        )
                                                                    }>
                                                                    <item.icon
                                                                        className="h-6 w-6 shrink-0"
                                                                        aria-hidden="true"
                                                                    />
                                                                    {item.name}
                                                                </NavLink>
                                                            </li>
                                                        ))}
                                                    </Disclosure.Panel>
                                                </>
                                            )}
                                        </Disclosure>
                                        <li>
                                            <div className="text-xs font-semibold leading-6 text-gray-400">
                                                Your Organisation
                                            </div>
                                            <ul role="list" className="-mx-2 mt-2 space-y-1">
                                                {orgNavigation.map((team) => (
                                                    <li key={team.name}>
                                                        <NavLink
                                                            to={team.href}
                                                            target={team.name === 'Billing' ? '_blank' : ''}
                                                            rel={team.name === 'Billing' ? 'noopener noreferrer' : ''}
                                                            className={({ isActive }) =>
                                                                classNames(
                                                                    isActive
                                                                        ? 'bg-gray-800 text-white'
                                                                        : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                                                )
                                                            }>
                                                            <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                                                                {team.initial}
                                                            </span>
                                                            <span className="truncate">{team.name}</span>
                                                        </NavLink>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                        <li className="mt-auto">
                                            <NavLink
                                                to="/dashboard/profile"
                                                className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white">
                                                <Cog6ToothIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                                Your Profile
                                            </NavLink>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>

                        <div className="lg:pl-72 min-h-screen">
                            <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
                                <button
                                    type="button"
                                    className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                                    onClick={() => setSidebarOpen(true)}>
                                    <span className="sr-only">Open sidebar</span>
                                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                                </button>

                                {/* Separator */}
                                <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />

                                <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                                    <NavSearch />
                                    <div className="flex items-center gap-x-4 lg:gap-x-6">
                                        {/* <button
                                            type="button"
                                            className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
                                            <span className="sr-only">View notifications</span>
                                            <BellIcon className="h-6 w-6" aria-hidden="true" />
                                        </button> */}

                                        {/* Separator */}
                                        <div
                                            className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                                            aria-hidden="true"
                                        />

                                        {/* Profile dropdown */}
                                        <Menu as="div" className="relative">
                                            <Menu.Button className="-m-1.5 flex items-center p-1.5">
                                                <span className="sr-only">Open user menu</span>
                                                <UserCircleIcon className="h-8 w-8 rounded-full text-gray-400" />
                                                <span className="hidden lg:flex lg:items-center">
                                                    <span
                                                        className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                                                        aria-hidden="true">
                                                        {authContext.firstName} {authContext.lastName}
                                                    </span>
                                                    <ChevronDownIcon
                                                        className="ml-2 h-5 w-5 text-gray-400"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                            </Menu.Button>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95">
                                                <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <NavLink
                                                                to="/"
                                                                onClick={logout}
                                                                className={classNames(
                                                                    active ? 'bg-gray-100' : '',
                                                                    'block px-4 py-2 text-sm text-gray-700',
                                                                )}>
                                                                Sign Out
                                                            </NavLink>
                                                        )}
                                                    </Menu.Item>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    </div>
                                </div>
                            </div>

                            <main className="py-10 bg-gray-100 min-h-[calc(100vh-4rem)]">
                                <div className="px-4 sm:px-6 lg:px-8 ">
                                    <Outlet />
                                </div>
                            </main>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <Disclosure as="nav" className="bg-gray-800">
                        {({ open }) => (
                            <>
                                <div className="mx-auto max-w-screen-2xl px-2 sm:px-6 lg:px-8">
                                    <div className="relative flex h-16 items-center justify-between">
                                        <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                            {/* Mobile menu button*/}
                                            <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                                <span className="sr-only">Open main menu</span>
                                                {open ? (
                                                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                                ) : (
                                                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                                )}
                                            </Disclosure.Button>
                                        </div>
                                        <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                                            <div className="flex flex-shrink-0 items-center">
                                                <NavLink to="/">
                                                    <img className="h-8 w-auto" src={logoNoBg} alt="Webbsly Logo" />
                                                </NavLink>
                                            </div>
                                            <div className="hidden sm:ml-6 sm:block">
                                                <div className="flex space-x-4">
                                                    {navigation.map((item) => (
                                                        <NavLink
                                                            key={item.name}
                                                            to={item.href}
                                                            className={({ isActive }) =>
                                                                classNames(
                                                                    isActive
                                                                        ? 'bg-gray-900 text-white'
                                                                        : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                                    'rounded-md px-3 py-2 text-sm font-medium',
                                                                )
                                                            }>
                                                            {item.name}
                                                        </NavLink>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                            {authContext.isLoggedIn ? (
                                                <></>
                                            ) : (
                                                <>
                                                    <NavLink
                                                        key="Login"
                                                        to="/login"
                                                        className={({ isActive }) =>
                                                            classNames(
                                                                isActive
                                                                    ? 'bg-gray-900 text-white'
                                                                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                                'rounded-md px-3 py-2 text-sm font-medium',
                                                            )
                                                        }>
                                                        Sign In
                                                    </NavLink>
                                                    <NavLink
                                                        key="Register"
                                                        to="/register"
                                                        className={({ isActive }) =>
                                                            classNames(
                                                                isActive
                                                                    ? 'bg-gray-900 text-white'
                                                                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                                'rounded-md px-3 py-2 text-sm font-medium',
                                                            )
                                                        }>
                                                        Register
                                                    </NavLink>
                                                </>
                                            )}

                                            {/* Profile dropdown */}
                                            {authContext.isLoggedIn && (
                                                <Menu as="div" className="relative ml-3">
                                                    <div>
                                                        <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                                            <span className="sr-only">Open user menu</span>
                                                            <img
                                                                className="h-8 w-8 rounded-full"
                                                                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                                                alt=""
                                                            />
                                                        </Menu.Button>
                                                    </div>

                                                    <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-100"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95">
                                                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <NavLink
                                                                        to="/dashboard"
                                                                        className={classNames(
                                                                            active ? 'bg-gray-100' : '',
                                                                            'block px-4 py-2 text-sm text-gray-700',
                                                                        )}>
                                                                        Dashboard
                                                                    </NavLink>
                                                                )}
                                                            </Menu.Item>
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <NavLink
                                                                        to="#"
                                                                        className={classNames(
                                                                            active ? 'bg-gray-100' : '',
                                                                            'block px-4 py-2 text-sm text-gray-700',
                                                                        )}>
                                                                        Settings
                                                                    </NavLink>
                                                                )}
                                                            </Menu.Item>
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <NavLink
                                                                        to="/"
                                                                        onClick={logout}
                                                                        className={classNames(
                                                                            active ? 'bg-gray-100' : '',
                                                                            'block px-4 py-2 text-sm text-gray-700',
                                                                        )}>
                                                                        Sign Out
                                                                    </NavLink>
                                                                )}
                                                            </Menu.Item>
                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                {/* <Transition
                            // as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        > */}
                                <Disclosure.Panel className="sm:hidden">
                                    {({ close }) => (
                                        <div className="space-y-1 px-2 pb-3 pt-2">
                                            {navigation.map((item) => (
                                                <NavLink
                                                    key={item.name}
                                                    to={item.href}
                                                    onClick={() => close()}
                                                    className={({ isActive }) =>
                                                        classNames(
                                                            isActive
                                                                ? 'bg-gray-900 text-white'
                                                                : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                            'block rounded-md px-3 py-2 text-base font-medium',
                                                        )
                                                    }>
                                                    {item.name}
                                                </NavLink>
                                            ))}
                                        </div>
                                    )}
                                </Disclosure.Panel>
                                {/* </Transition> */}
                            </>
                        )}
                    </Disclosure>
                    <Outlet />
                </>
            )}
        </div>
    );
}
