import { classNames } from '../domain/helpers';

type ButtonProps = {
    children?: React.ReactNode;
    text?: string;
    type?: 'submit' | 'button' | 'reset';
    className?: string;
    style?: React.CSSProperties;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    width?: string;
    py?: string;
    px?: string;
};

function Button({
    children,
    text,
    type = 'submit',
    className = '',
    style,
    onClick,
    disabled = false,
    width = 'w-full',
    py = 'py-1.5',
    px = 'px-3',
}: ButtonProps) {
    return (
        <button
            type={type}
            style={style}
            onClick={onClick}
            disabled={disabled}
            className={classNames(
                'flex justify-center rounded-md bg-indigo-600 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
                className,
                disabled ? 'opacity-50 cursor-not-allowed' : '',
                width,
                py,
                px,
            )}>
            {children || text}
        </button>
    );
}

export default Button;
