import { LocalRouter } from './domain/routes';
import { Toaster } from 'react-hot-toast';
import { useModalContext, useModalContextResponse, useNotificationContext } from './utils/Context';
import { HelmetProvider } from 'react-helmet-async';
import Modal from './components/Modal';
import Notification from './components/Notification';
import { setAxiosDomains } from './domain/helpers';
setAxiosDomains();

function App() {
    const [modalContext, setModalContext] = useModalContext((state) => [state.modalContext, state.changeState]);
    const [notificationContext] = useNotificationContext((state) => [state.notificationContext, state.changeState]);
    const setModalResponse = useModalContextResponse((state) => state.changeState);

    return (
        <HelmetProvider>
            <Toaster
                toastOptions={{
                    success: {
                        duration: 3000,
                    },
                }}
                reverseOrder={true}
            />
            <Modal
                open={modalContext.isOpen}
                callBackOnClose={modalContext.callBackOnClose}
                closeModal={() => {
                    setModalContext({ isOpen: false, children: null });
                    setModalResponse(null);
                }}>
                {modalContext.children}
            </Modal>
            <Notification
                isOpen={notificationContext.isOpen}
                heading={notificationContext.heading}
                message={notificationContext.message}
                acceptName={notificationContext.acceptName}
                declineName={notificationContext.declineName}
                acceptFunction={notificationContext.acceptFunction}
                declineFunction={notificationContext.declineFunction}
                icon={notificationContext.icon}
            />
            <LocalRouter />
        </HelmetProvider>
    );
}

export default App;
