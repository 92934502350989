import { classNames } from "../domain/helpers"

type SpinnerProps = {
    h?: string
    w?: string
    className?: string
    showLoadingText?: boolean;
}


function Spinner({ h = 'h-8', w = 'w-8', className = "", showLoadingText = true }: SpinnerProps) {
    return (
        <div className={classNames(className)}>
            <div
                className={classNames("inline-block animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-info motion-reduce:animate-[spin_1.5s_linear_infinite]", h, w)}
                role="status"
            >
                {showLoadingText &&
                    <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                        Loading...
                    </span>
                }
            </div>
        </div>
    )
}

export default Spinner