import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from '../pages/Home';
import Nav from '../components/Nav';
import ProtectedRoute from '../components/ProtectedRoute';
import ActivateAccount from '../pages/ActivateAccount';
import Page404 from '../pages/404';
import Maintenance from '../pages/Maintenance';
import loadable from '@loadable/component';
import Spinner from '../components/Spinner';

function createSpinner() {
    return (
        <div className="text-center mt-[10vh]">
            <Spinner h="h-32" w="w-32" />
            <p className="mt-4 text-2xl font-semibold">Page is loading...</p>
        </div>
    );
}

const Dashboard = loadable(() => import('../pages/Dashboard'), {
    fallback: createSpinner(),
});

const Login = loadable(() => import('../pages/Login'), {
    fallback: createSpinner(),
});
const Register = loadable(() => import('../pages/Register'), {
    fallback: createSpinner(),
});
const PasswordReset = loadable(() => import('../pages/PasswordReset'), {
    fallback: createSpinner(),
});

const CreateWebinar = loadable(() => import('../pages/CreateWebinar'), {
    fallback: createSpinner(),
});
const Content = loadable(() => import('../pages/Content'), {
    fallback: createSpinner(),
});
const Calendar = loadable(() => import('../pages/Calendar'), {
    fallback: createSpinner(),
});
const OrganisationManagement = loadable(() => import('../pages/OrgManagement'), {
    fallback: createSpinner(),
});

const EventJoin = loadable(() => import('../pages/EventJoin'), {
    fallback: createSpinner(),
});

export const LocalRouter = () => (
    <BrowserRouter>
        <Routes>
            {import.meta.env.MODE === 'prod' ? (
                <Route path="*" element={<Maintenance />} />
            ) : (
                <Route element={<Nav />}>
                    {/* // Protected Routes */}
                    <Route element={<ProtectedRoute />}>
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/dashboard/webinars/create" element={<CreateWebinar />} />
                        <Route path="/dashboard/content" element={<Content />} />
                        <Route path="/dashboard/calendar" element={<Calendar />} />
                        <Route path="/dashboard/management" element={<OrganisationManagement />} />
                    </Route>
                    <Route path="/activate/:userId?/:uuid/:orgId?" element={<ActivateAccount />} />

                    {/* // Public Routes */}
                    <Route index element={<Home />} />
                    <Route path="/maintenance" element={<Maintenance />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/recover/:uuid?" element={<PasswordReset />} />
                    <Route path="/event/:eventId/join" element={<EventJoin />} />
                    {/* <Route path="/pricing" element={<PricingComponent onClick={null}/>} /> */}

                    {/* Keep this as last route for 404 pages */}
                    <Route path="*" element={<Page404 />} />
                </Route>
            )}
        </Routes>
    </BrowserRouter>
);
