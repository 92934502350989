import algoliasearch, { SearchClient } from 'algoliasearch/lite';
import { Hits, InstantSearch, SearchBox } from 'react-instantsearch';
import { MultipleQueriesQuery } from '@algolia/client-search';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
const algoliaClient = algoliasearch('N86DVMXZHN', '4535c012d595f665039e6856be248344');
const indexName = 'webbsly-dev';

let timerId: NodeJS.Timeout;
let timeout = 300;

const searchClient: SearchClient = {
    ...algoliaClient,
    //@ts-ignore
    search(requests: readonly MultipleQueriesQuery[]) {
        //@ts-ignore
        if (requests.every(({ params }) => !params.query)) {
            return Promise.resolve({
                results: requests.map(() => ({
                    hits: [],
                    nbHits: 0,
                    nbPages: 0,
                    page: 0,
                    processingTimeMS: 0,
                    hitsPerPage: 0,
                    exhaustiveNbHits: false,
                    query: '',
                    params: '',
                })),
            });
        }

        return algoliaClient.search(requests);
    },
};

const useOutsideClick = (callback: any) => {
    const ref = useRef<any>();

    useEffect(() => {
        const handleClick = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback(false);
            } else callback(true);
        };

        document.addEventListener('click', handleClick, true);

        return () => {
            document.removeEventListener('click', handleClick, true);
        };
    }, [ref]);

    return ref;
};

export default function NavSearch() {
    // const [query, setQuery] = useState<string | undefined>('');
    // const [data, setData] = useState<Array<any>>([]);
    const [showHits, setShowHits] = useState<boolean>(true);
    // const location = useLocation();
    const navigate = useNavigate();

    const ref = useOutsideClick(setShowHits);

    // function searchResultOnClick(redirectUrl: string) {
    //     navigate(redirectUrl);
    // }

    // const onStateChange: InstantSearchProps['onStateChange'] = ({ uiState, setUiState }) => {
    //     //@ts-ignore
    //     if (uiState['webbsly-dev'].query?.length > 0) {
    //         setShowHits(true);
    //     }
    //     setQuery(uiState['webbsly-dev'].query);
    //     setUiState(uiState);
    // };

    // useEffect(() => {
    //     if (showHits) setShowHits(false);
    // }, [location]);

    function Hit({ hit }: any) {
        return (
            <div
                className="flex gap-1 justify-start text-center items-center w-full"
                onClick={() => {
                    navigate(`/dashboard/${hit.type === 'event' ? 'webinar' : ''}/${hit.id}`);
                }}>
                <img
                    src={
                        hit.imgUrl
                            ? hit.imgUrl
                            : 'https://develop-static.webbsly.com/file/webbsly-local-dev/512x512-logo.png'
                    }
                    alt=""
                    className="h-8 w-8 flex-none rounded-full"
                />
                <h1>{hit.name}</h1>
            </div>
        );
    }

    return (
        <>
            <div
                ref={ref}
                id="parent-search"
                className="relative flex flex-1"
                // className="block h-full w-full border-0 py-0 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 outline-none sm:text-sm"
            >
                <div className="w-full">
                    <InstantSearch searchClient={searchClient} indexName={indexName}>
                        <SearchBox
                            resetIconComponent={({}) => <></>}
                            loadingIconComponent={({}) => <></>}
                            placeholder="Search..."
                            classNames={{
                                submitIcon: 'pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400',
                                root: 'block h-full w-full border-0 py-0 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 outline-none sm:text-sm',
                                form: 'w-full relative flex flex-1 block h-full w-full border-0 py-0 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 outline-none sm:text-sm',
                                input: 'block h-full w-full border-0 py-0 pl-10 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 outline-none sm:text-sm shadow-none',
                            }}
                            queryHook={(query, refine) => {
                                // if (query.trim().length === 0) return;
                                clearTimeout(timerId);
                                timerId = setTimeout(() => refine(query), timeout);
                            }}
                        />
                        {showHits && (
                            <div className="relative w-full z-[99999] bg-slate-300">
                                <Hits
                                    classNames={{
                                        item: 'flex gap-1 cursor-pointer',
                                    }}
                                    hitComponent={Hit}
                                />
                            </div>
                        )}
                    </InstantSearch>
                </div>
            </div>

            {/* <form className="relative flex flex-1" action="#" method="GET">
                <label htmlFor="search-field" className="sr-only">
                    Search
                </label>
                <MagnifyingGlassIcon
                    className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                    aria-hidden="true"
                />

                <input
                    id="search-field"
                    className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 outline-none sm:text-sm"
                    placeholder="Search..."
                    type="search"
                    name="search"
                />
            </form> */}
        </>
    );
}
