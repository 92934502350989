import { classNames } from '../domain/helpers';

type TextInputProps = {
    id: string;
    name: string;
    type: string;
    autocomplete?: string;
    required: boolean;
    className?: string;
    style?: React.CSSProperties;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    placeholder?: string;
    pattern?: string;
    onInvalid?: (event: React.FormEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    readOnly?: boolean;
    children?: React.ReactNode;
    decorationRight?: React.ReactNode;
    decorationRightOnClick?: (event: React.MouseEvent<any>) => void;
    value?: any;
};

export default function TextInput({
    id,
    name,
    type,
    autocomplete,
    required,
    className = '',
    style,
    onBlur,
    onChange,
    placeholder,
    pattern,
    onInvalid,
    disabled = false,
    readOnly = false,
    value,
    decorationRight,
    decorationRightOnClick,
}: TextInputProps) {
    return (
        <>
            <div className="relative shadow-sm">
                <input
                    id={id}
                    name={name}
                    type={type}
                    autoComplete={autocomplete}
                    required={required}
                    style={style}
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder={placeholder}
                    pattern={pattern}
                    onInvalid={onInvalid}
                    disabled={disabled}
                    readOnly={readOnly}
                    value={value}
                    className={classNames(
                        'w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 appearance-none pl-2',
                        className,
                    )}
                />
                {decorationRight ? (
                    <div
                        id="input-decoration-right"
                        className="absolute inset-y-0 right-0 flex items-center pr-3 z-20 cursor-pointer"
                        onClick={(e) => {
                            if (decorationRightOnClick !== undefined) decorationRightOnClick(e);
                        }}>
                        <span className="text-gray-500 sm:text-sm">{decorationRight}</span>
                    </div>
                ) : null}

                {/* <input
                    type="text"
                    name="price"
                    id="price"
                    className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="0.00"
                /> */}
            </div>
        </>
    );
}
