import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import axios from 'axios';

axios.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        config.withCredentials = true;
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    },
);

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);
